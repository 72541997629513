<template>

<app-content :loading="is.loading && !is.initialised">

	<app-content-head title="Purchases" subtitle="Manage the your purchases and transactions." />

	<app-content-body :loading="is.loading && is.initialised" :is-empty="!collection.length" placeholder="Sorry, no purchases found.">

		<app-table>

			<app-table-head :layout="layout">

				<app-table-column text="Ref." />
				<app-table-column text="Status" />
				<app-table-column text="Convention" />
				<app-table-column text="Date" />
				<app-table-column align="center" text="Badges" />
				<app-table-column align="center" text="Items" />
				<app-table-column text="Total" />

			</app-table-head>

			<app-table-body>

				<app-table-row v-on:click="onEditClick(item.id)" :danger="item.status === $constants.registration.sale.status.refunded" :warning="item.status === $constants.registration.sale.status.partial" :layout="layout" v-for="item in collection" :key="item.id">

					<app-table-cell-text :text="item.reference" />
					<app-table-cell-label :type="$constants.registration.sale.statusType[item.status]" :text="$constants.registration.sale.status[item.status]" />
					<app-table-cell-text :text="item.convention.fullname" />
					<app-table-cell-text :text="item.date.made | localDate('MM/DD/YYYY h:mma')" />
					<app-table-cell-text :text="item.count.badges" align="center" />
					<app-table-cell-text :text="item.count.addons" align="center" />
					<app-table-cell-text :text="item.cost.total | formatMoney" />

				</app-table-row>

			</app-table-body>

		</app-table>

	</app-content-body>

</app-content>

</template>

<script>

import mixCollection from '@/mixin/collection'

export default {

	mixins: [mixCollection],

	data: function() {

		return {
			pagination: false,
			filterStatusOptions: [
				{ value: 1, text: 'Completed' },
				{ value: 2, text: 'Refunded' },
				{ value: 3, text: 'Partial Refund' }
			],
			layout: '60px 100px auto 150px 80px 80px 80px',
			endpoint: 'purchases'
		}

	}

}

</script>

<style scoped>

</style>